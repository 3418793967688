import axios from "axios";
import * as Sentry from "@sentry/vue";
import { useDialogStore } from "../stores/dialog";


export function useDataAPI() {
    const dialogStore = useDialogStore();
    const VITE_EUDR_BACKEND = import.meta.env.VITE_EUDR_BACKEND;

    // Create base Axios instance for general API calls
    const axiosInstance = axios.create({
        baseURL: VITE_EUDR_BACKEND,
    });

    const axiosInstanceWithoutSessionExpired = axios.create({
        baseURL: VITE_EUDR_BACKEND,
    });

    axiosInstanceWithoutSessionExpired.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        Sentry.withScope(scope => {
            scope.setTag('url', error.config.url);
            scope.setLevel('error');
            scope.setExtra('browser_online', navigator.onLine);
            scope.setExtra('error_message', error.message);

            if (error.response) {
                if (error.response.status === 401) {
                    // 401 errors are not logged to Sentry because they are typically due to expired sessions or unauthorized states
                } else if (error.response.status === 403) {
                    // 403 errors are also not logged to Sentry because they are due to forbidden access, not application errors
                } else {
                    scope.setTag('error_type', 'api_error');
                    scope.setExtra('response', {
                        data: error.response.data,
                        status: error.response.status,
                        headers: error.response.headers,
                    });
                    Sentry.captureException(error);
                }
            } else if (error.request) {
                scope.setTag('error_type', 'network_error');
                scope.setExtra('network_error_details', {
                    request: error.request,
                    config: error.config
                });
                Sentry.captureException(error);
            }
            else {
                scope.setTag('error_type', 'setup_error');
                scope.setExtra('setup_error_details', {
                    config: error.config
                });
                Sentry.captureException(error);
            }
        });
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(response => {
        // Handle the response data normally
        return response;
    }, error => {
        Sentry.withScope(scope => {
            scope.setTag('url', error.config.url);
            scope.setLevel('error');
            scope.setExtra('browser_online', navigator.onLine);
            scope.setExtra('error_message', error.message);

            if (error.response) {
                if (error.response.status === 401) {
                    dialogStore.isSessionExpired = true;
                } else if (error.response.status === 403) {
                    // 403 errors are also not logged to Sentry because they are due to forbidden access, not application errors
                } else {
                    scope.setTag('error_type', 'api_error');
                    scope.setExtra('response', {
                        data: error.response.data,
                        status: error.response.status,
                        headers: error.response.headers,
                    });
                    Sentry.captureException(error);
                }
            } else if (error.request) {
                scope.setTag('error_type', 'network_error');
                scope.setExtra('network_error_details', {
                    request: error.request,
                    config: error.config
                });
                Sentry.captureException(error);
            }
            else {
                scope.setTag('error_type', 'setup_error');
                scope.setExtra('setup_error_details', {
                    config: error.config
                });
                Sentry.captureException(error);
            }
        });
        return Promise.reject(error);
    });


    // GET
    async function getImpersonateToken(adminUid, userUid) {
        const response = await axiosInstance.get(`/auth/generate/${adminUid}/${userUid}`);
        return response.data
    }

    async function getSupplier(soid) {
        const response = await axiosInstance.get(`/suppliers/${soid}`, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getAllSuppliers() {
        const response = await axiosInstance.get(`/suppliers`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getUserGuide() {
        const response = await axiosInstance.get(`/files/user-guide`, {
            responseType: 'blob', // Important for binary files like zip
            withCredentials: true,
        });

        // Create a URL for the blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        // Create a temporary anchor tag to download the file
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Nadar_EUDR_User_Guide.zip'); // Set the file name for the download
        document.body.appendChild(fileLink);

        fileLink.click(); // Trigger the download

        // Cleanup
        fileLink.remove();
        window.URL.revokeObjectURL(fileURL);
    }

    async function getCurrentUser() {
        const response = await axiosInstance.get(`/users/me`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getAllUsers() {
        const response = await axiosInstance.get(`/users`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getCurrentOrganization() {
        const response = await axiosInstance.get(`/organization`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getAllStatements() {
        const response = await axiosInstance.get(`/duediligence`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getDownloadStatement(stoid) {
        const response = await axiosInstance.get(`/duediligence/${stoid}`, {
            withCredentials: true,
            responseType: "blob"
        });
        return response.data
    }

    async function getAllPlotsOfSupplier(soid) {
        const response = await axiosInstance.get(`/plots/supplier/${soid}`, {
            withCredentials: true,
        });
        return response.data
    }

    async function getMappingPlots() {
        const response = await axiosInstance.get(`/plots/mapping`, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getAllStatements() {
        const response = await axiosInstance.get(`/duediligence`, {
            withCredentials: true,
        });
        return response.data;
    }

    async function getDueDiligencePlots(pageSize, offset, filters) {
        const response = await axiosInstance.get(`/plots/duediligence`, {
            params: {
                limit: pageSize,
                offset: offset,
                filters: JSON.stringify(filters),
            },
            withCredentials: true,
        });
        return response.data;
    }

    async function getDueDiligenceStatement(dueDiligenceObjectId) {
        const response = await axiosInstance.get(`/duediligence/${dueDiligenceObjectId}`, {
            withCredentials: true,
        });
        return response.data;
    }

    //POST

    async function postDeforestationCheckExportGeoJSON(body) {
        const response = await axiosInstance.post(`/plots/deforestation-check/export/geojson`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function postDeforestationCheckExportCSV(body) {
        const response = await axiosInstance.post(`/plots/deforestation-check/export/csv`, body, {
            responseType: 'blob',
            withCredentials: true,
        });
        return response.data
    }

    async function postDeforestationCheckExportExcel(body) {
        const response = await axiosInstance.post(`/plots/deforestation-check/export/excel`, body, {
            responseType: 'blob',
            withCredentials: true,
        });
        return response.data
    }

    async function postOverlappingPlots(body) {
        const response = await axiosInstance.post(`/plots/overlapping`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function postUser(body) {
        const response = await axiosInstance.post(`/users`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function postSupplier(body) {
        const response = await axiosInstance.post(`/suppliers`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function postLoginUser(idToken) {
        const response = await axiosInstance.post(`/auth/login`, {}, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });
        return response.data
    }

    async function postLoginAdmin(idToken) {
        const response = await axiosInstance.post(`/auth/login-admin`, {}, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        });
        return response.data
    }

    async function postLogoutUser() {
        const response = await axiosInstanceWithoutSessionExpired.post(`/auth/logout`, {}, {
            withCredentials: true,
        });
        return response.data
    }

    async function postVerifyUser() {
        const response = await axiosInstanceWithoutSessionExpired.post(`/auth/verify`, {}, {
            withCredentials: true,
        });
        return response
    }

    async function postDDS(body) {
        const response = await axiosInstance.post(`/duediligence`, body, {
            withCredentials: true,
        });
        return response.data
    }

    async function postVerificationGeoJSON(body) {
        const response = await axiosInstance.post(`/plots/verification/export/geojson`, body, {
            withCredentials: true,
        });
        return response.data
    }

    // PATCH

    async function patchCurrentUser(body) {
        const response = await axiosInstance.patch(`/users/me`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function patchUser(uoid, body) {
        const response = await axiosInstance.patch(`/users/${uoid}`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function patchCurrentOrganization(body) {
        const response = await axiosInstance.patch(`/organization`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function patchSupplier(soid, body) {
        const response = await axiosInstance.patch(`/suppliers/${soid}`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function patchSupplierCommodities(soid, body) {
        const response = await axiosInstance.patch(`/suppliers/${soid}/commodities`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function patchPlot(poid, body) {
        const response = await axiosInstance.patch(`/plots/${poid}`, body, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data
    }

    async function deleteUser(uoid) {
        const response = await axiosInstance.delete(`/users/${uoid}`, {
            withCredentials: true,
        });
        return response.data
    }

    async function deleteSupplier(soid) {
        const response = await axiosInstance.delete(`/suppliers/${soid}`, {
            withCredentials: true,
        });
        return response.data
    }

    async function deleteStatement(dueDiligenceObjectId) {
        const response = await axiosInstance.delete(`/duediligence/${dueDiligenceObjectId}`, {
            withCredentials: true,
        });
        return response.data
    }


    return {
        getImpersonateToken,
        getCurrentOrganization,
        getSupplier,
        getAllUsers,
        getAllSuppliers,
        getCurrentUser,
        getUserGuide,
        getAllStatements,
        getMappingPlots,
        getDownloadStatement,
        getAllPlotsOfSupplier,
        getDueDiligencePlots,
        getDueDiligenceStatement,
        postVerificationGeoJSON,
        postDeforestationCheckExportGeoJSON,
        postDeforestationCheckExportCSV,
        postDeforestationCheckExportExcel,
        postSupplier,
        postLoginUser,
        postUser,
        postDDS,
        postOverlappingPlots,
        postLoginAdmin,
        postLogoutUser,
        postVerifyUser,
        patchSupplier,
        patchCurrentOrganization,
        patchSupplierCommodities,
        patchCurrentUser,
        patchUser,
        patchPlot,
        deleteSupplier,
        deleteUser,
        deleteStatement,
    }
}

