<template>
    <Dialog v-model:visible="dialogStore.isSessionExpired" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column m-4 p-2 gap-2 justify-content-center">
                <span class="text-lg">Your session has expired</span>
                <span class="block mb-3">Please log in again.</span>
                <div class="flex justify-content-end gap-2">
                    <Button type="button" label="Log in" @click="loginAgain()"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { useDialogStore } from "../../stores/dialog";
import { useRouter } from "vue-router";


const dialogStore = useDialogStore();
const router = useRouter();


function loginAgain() {
    dialogStore.isSessionExpired = false;
    router.push({ name: "login" });
}
</script>