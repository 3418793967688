import { defineStore } from 'pinia'

export const useDialogStore = defineStore('dialog', {
    state: () => ({
        isSessionExpired: false,
        isVerificationSuccessful: false,
        isVerificationFailed: false,
        isDeforestationCheckFailed: false,
        isDeforestationCheckSuccessful: false,
        isDeforestationCheckGoingOn: false,
        isVerificationGoingOn: false,
        isCrsDetectionGoingOn: false,
        isCrsDetectionFailed: false
    })
})