<template>
    <Dialog v-model:visible="dialogStore.isDeforestationCheckGoingOn" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column m-4 p-2 gap-2 justify-content-center">
                <span class="text-xl">Please wait...</span>
                <div>
                    <i class="pi pi-spin pi-spinner mr-2"></i>
                    <span>Checking plots for deforestation. This may take a few moments.</span>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { useDialogStore } from '../../stores/dialog';

const dialogStore = useDialogStore();
</script>