<template>
    <Dialog v-model:visible="dialogStore.isCrsDetectionFailed" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column m-4 p-2 gap-2 justify-content-center">
                <div>
                    <i class="pi pi-exclamation-circle text-center" style="font-size: 1rem; color:red"></i>
                    <span class="text-xl"> File import failed</span>
                </div>
                <span class="block mb-3">GeoJSON or shapefile cannot be imported. Please make sure that the GeoJSON is
                    formatted correctly or that the shapefile components (.shp, .shx, .dbf, .prj) are zipped together
                    without an additional folder. Try again or contact support.</span>
                <div class="flex justify-content-end gap-2">
                    <Button severity="danger" label="OK" @click="closeCallback"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { useDialogStore } from '../../stores/dialog';

const dialogStore = useDialogStore();
</script>