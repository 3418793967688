<template>
    <Dialog v-model:visible="dialogStore.isVerificationSuccessful" modal :style="{ width: '30rem' }">
        <template #container="{ closeCallback }">
            <div class="flex flex-column m-4 p-2 gap-2 justify-content-center">
                <div>
                    <i class="pi pi-check mr-1" style="color:green"></i>
                    <span class="text-lg">Verification completed</span>
                </div>
                <span class="block mb-3">The results are now available in the "Verification" Page.</span>
                <div class="flex justify-content-end gap-2">
                    <Button class="light-green-nadar" label="OK" @click="closeCallback"></Button>
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { useDialogStore } from '../../stores/dialog';

const dialogStore = useDialogStore();
</script>