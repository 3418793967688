import { defineStore } from 'pinia'
import UAParser from 'ua-parser-js';


export const useDeviceStore = defineStore('device', {
    state: () => ({
        device: "",
    }),
    actions: {
        detectDevice() {
            let parser = new UAParser();
            let result = parser.getResult();
            let deviceType = result.device.type;  // This can be 'undefined' for desktops

            // For desktops, `deviceType` will be `undefined`, so set it explicitly
            if (!deviceType) {
                this.device = "desktop";
                return;
            }

            if (deviceType !== "desktop" && deviceType !== "mobile") {
                this.device = "mobile";
                return;
            }

            this.device = deviceType;
        }
    }
})