import { createRouter, createWebHistory } from 'vue-router'
import { useDataAPI } from '../composables/useDataAPI';
import { useDeviceStore } from "../stores/device"
import { useUsersStore } from '../stores/users';
import { getAuth, verifyPasswordResetCode } from 'firebase/auth';
const LoginPage = () => import('../pages/LoginPage.vue');
const AdminLoginPage = () => import('../pages/AdminLoginPage.vue');
const ResetCredentialsPage = () => import('../pages/ResetCredentialsPage.vue');
const DashboardLayout = () => import('../layouts/DashboardLayout.vue');
const Supplier = () => import('../components/suppliers/Supplier.vue');
const SupplierOverviewPage = () => import('../pages/SupplierOverviewPage.vue');
const UserProfilePage = () => import('../pages/UserProfilePage.vue');
const MappingPage = () => import('../pages/MappingPage.vue');
const RiskAssessmentPage = () => import('../pages/RiskAssessmentPage.vue');
const DueDiligencePage = () => import('../pages/DueDiligencePage.vue');
const DataImportPage = () => import('../pages/DataImportPage.vue');
const CertificatePage = () => import('../pages/CertificatePage.vue');
const VerificationPage = () => import('../pages/VerificationPage.vue');
const MapPage = () => import('../pages/mobile/MapPage.vue');
const PlotsPage = () => import('../pages/mobile/PlotsPage.vue');
const ProfilePage = () => import('../pages/mobile/ProfilePage.vue');
const PrivacyPage = () => import('../pages/PrivacyPage.vue');
const NotFoundPage = () => import('../pages/404.vue');
const UserManagementPage = () => import('../pages/UserManagementPage.vue');
const OrganizationPage = () => import('../pages/OrganizationPage.vue');
const DeforestationCheckPage = () => import('../pages/DeforestationCheckPage.vue');



function canAccess(permission) {
  return (to, from, next) => {
    const usersStore = useUsersStore();
    if (usersStore.hasPermission(permission)) {
      next(); // Permission is valid, proceed
    } else {
      next('/login'); // Permission is denied, redirect to login
    }
  };
}

function hasValidSessionCookie() {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('session_eudr_nadar_expires_at='))
    ?.split('=')[1];

  if (cookieValue) {
    // Decode the URL-encoded cookie value
    const decodedValue = decodeURIComponent(cookieValue);
    const expirationTime = new Date(decodedValue).getTime();
    const currentTime = Date.now();
    return currentTime < expirationTime;
  }
  return false;
}

async function verifySessionGuard(to, from, next) {
  const usersStore = useUsersStore();
  const { postVerifyUser } = useDataAPI();

  if (navigator.onLine) {
    try {
      await postVerifyUser(); // Attempt to verify the user's session.
      await usersStore.fetchCurrentUser() // Fetch online and use offline
      // If the request succeeds, the user is considered authenticated.
      if (to.path === '/login') {
        // Authenticated users should not see the login page.
        next('/import');
        return;
      }
      next(); // For any other route, proceed as normal.
    } catch (error) {
      // The user is not authenticated.
      if (to.path !== '/login') {
        // If the target route is not /login, redirect to /login.
        next('/login');
      } else {
        // If the target route is /login, allow the user to proceed.
        next();
      }
    }
  } else {
    // User is offline, check if the session cookie is still valid
    if (hasValidSessionCookie()) {
      next(); // Session cookie is valid, proceed to the requested route
    } else {
      next("/login"); // Session cookie is invalid or not present, redirect to login
    }
  }
}

// Navigation guard for the /reset route
async function verifyResetCodeGuard(to, from, next) {
  const auth = getAuth();
  const oobCode = to.query.oobCode; // Get the oobCode from the URL query parameters
  const mode = to.query.mode;

  // Check if oobCode is present
  if (!oobCode && !mode) {
    next('/login'); // Redirect to login if no oobCode is provided
    return;
  }

  // Verify the oobCode
  try {
    if (mode === "resetPassword") {
      await verifyPasswordResetCode(auth, oobCode);
      next(); // oobCode is valid, proceed to the reset page
    } else if (mode === "recoverEmail") {
      next();
    } else {
      next('/login');
    }
  } catch (error) {
    console.error(error);
    // oobCode is invalid or expired, redirect to login or error page
    next('/login');
  }
}

// https://stackoverflow.com/questions/69300341/typeerror-failed-to-fetch-dynamically-imported-module-on-vue-vite-vanilla-set
const router = createRouter({
  history: createWebHistory(),
  strict: true,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      beforeEnter: [verifySessionGuard],
    },
    {
      path: '/cheesecake',
      name: 'impersonate',
      component: AdminLoginPage,
    },
    {
      path: '/reset',
      name: 'reset',
      component: ResetCredentialsPage,
      beforeEnter: verifyResetCodeGuard,
    },
    {
      path: '/',
      redirect: '/import',
      component: DashboardLayout,
      beforeEnter: [verifySessionGuard],
      children: [
        {
          path: 'suppliers',
          children: [
            {
              path: '',
              name: 'suppliers',
              component: SupplierOverviewPage,
            },
            {
              path: 'supplier',
              name: 'supplier',
              component: Supplier,
            },
          ]
        },
        {
          path: 'deforestation',
          name: 'deforestation',
          component: DeforestationCheckPage,
        },
        {
          path: 'profile',
          name: 'profile',
          component: UserProfilePage,
        },
        {
          path: 'mapping',
          name: 'mapping',
          component: MappingPage,
        },
        {
          path: 'assessment',
          name: 'assessment',
          component: RiskAssessmentPage,
        },
        {
          path: 'duediligence',
          name: 'duediligence',
          component: DueDiligencePage,
        },
        {
          path: 'import',
          name: 'import',
          component: DataImportPage,
        },
        {
          path: 'certificate',
          name: 'certificate',
          component: CertificatePage,
        },
        {
          path: 'verification',
          name: 'verification',
          component: VerificationPage,
        },
        {
          path: 'user-management',
          name: 'user-management',
          component: UserManagementPage,
          beforeEnter: [canAccess("VIEW_USER_MANAGEMENT")],
        },
        {
          path: 'organization',
          name: 'organization',
          component: OrganizationPage,
          beforeEnter: [canAccess("VIEW_ORGANIZATION")],
        },
        {
          path: 'onboarding',
          redirect: '/map',
          children: [
            {
              path: 'map',
              name: 'onboarding-map',
              component: MapPage,
            },
            {
              path: 'plots',
              name: 'onboarding-plots',
              component: PlotsPage,
            },
            {
              path: 'profile',
              name: 'onboarding-profile',
              component: ProfilePage,
            },
          ]
        },
        /*
        {
          path: '/sentry',
          name: 'sentry',
          component: SentryTestPage,
        },
        */
      ]
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyPage,
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: NotFoundPage,
    },
  ]
});

router.beforeEach(async (to, from, next) => {
  const deviceStore = useDeviceStore();
  deviceStore.detectDevice();

  // Redirect mobile users accessing the root path to /onboarding/map
  if (deviceStore.device === 'mobile' && to.path === '/import') {
    next('/onboarding/map');
    return;
  }

  next();
});

export default router
